'use client'

import { Button } from '@nextui-org/button'
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react'
import { LanguagesIcon } from 'lucide-react'
import { useParams, usePathname } from 'next/navigation'
import { type Key } from 'react'

import { locales } from '@/libs/intl'
import { cls } from '@/libs/utils'

type Props = {
  radius?: 'none' | 'sm' | 'md' | 'lg' | 'full'
  className?: string
  iconClassName?: string
  disableRipple?: boolean
}

export function TranslateComponent({
  className,
  iconClassName,
  radius = 'none',
  disableRipple = true
}: Props) {
  // __STATE's
  const params = useParams()
  const pathname = usePathname()

  // __FUNCTION's
  const handleAction = (locale: Key) => {
    location.assign(pathname.replace(`/${params.locale}`, `/${locale}`))
  }

  // __RENDER
  return (
    <Dropdown classNames={{ content: 'min-w-max rounded-sm' }} shouldBlockScroll={false}>
      <DropdownTrigger>
        <Button
          className={cls('self-center bg-transparent', className)}
          radius={radius}
          size='md'
          isIconOnly
          disableRipple={disableRipple}
          aria-label='Menu translate'>
          <LanguagesIcon className={cls('size-5 text-white/80', iconClassName)} />
        </Button>
      </DropdownTrigger>

      <DropdownMenu
        color='primary'
        variant='flat'
        selectionMode='single'
        selectedKeys={params.locale}
        defaultSelectedKeys={params.locale}
        onAction={handleAction}>
        {locales.map((record) => (
          <DropdownItem className='rounded-sm font-bold' key={record.key}>
            {record.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}
