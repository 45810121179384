'use client'

import {
  Accordion,
  AccordionItem,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure
} from '@nextui-org/react'
import { AlignLeftIcon, UserRoundIcon, XIcon } from 'lucide-react'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { useMemo } from 'react'

import { abouts, destinations, gifts, massages, packages, treatments } from '@/constants/menu'
import { Link } from '@/libs/intl'

import { TranslateComponent } from './translate'

export function FnsComponent() {
  // __RENDER
  return (
    <div className='ui--navbar-container for-md'>
      <div className='context'>
        <div className='flex justify-start gap-1'>
          <DrawerComponent />
        </div>

        <div className='flex justify-center'>
          <Image
            className='size-12 object-contain object-center'
            src='/static/images/logo-sm.png'
            width={48}
            height={48}
            quality={100}
            priority
            alt='Oasis Spa Origin logo'
          />
        </div>

        <div className='flex justify-end gap-1'>
          {/* <Button
            className='bg-transparent'
            radius='sm'
            size='md'
            isIconOnly
            aria-label='Menu shopping cart'>
            <UserRoundIcon className='size-5 text-theme' />
          </Button> */}

          <TranslateComponent iconClassName='size-5 text-theme' radius='sm' disableRipple={false} />
        </div>
      </div>
    </div>
  )
}

export function DrawerComponent() {
  // __STATE's
  const { isOpen, onOpen, onOpenChange } = useDisclosure()

  // __RENDER
  return (
    <>
      <Button
        className='bg-transparent'
        radius='sm'
        size='md'
        isIconOnly
        onPress={onOpen}
        aria-label='Hamberger menu'>
        <AlignLeftIcon className='size-7 text-theme-orange' strokeWidth={2.5} />
      </Button>

      <Modal
        classNames={{ header: 'h-14 border-b border-theme-100 px-2 py-0', closeButton: 'hidden' }}
        motionProps={{
          variants: {
            enter: {
              x: 0,
              opacity: 1,
              transition: { duration: 0.2, ease: 'anticipate' }
            },
            exit: {
              x: -20,
              opacity: 0,
              transition: { duration: 0.2 }
            }
          }
        }}
        size='full'
        scrollBehavior='inside'
        isKeyboardDismissDisabled
        isDismissable={false}
        isOpen={isOpen}
        onOpenChange={onOpenChange}>
        <DrawerContext />
      </Modal>
    </>
  )
}

export function DrawerContext() {
  // __STATE's
  const t = useTranslations()

  const items = useMemo(
    () => [
      { label: t('Menu.MENU_HOME'), href: '/' },
      { label: t('Menu.MENU_NEWS_EVENT'), href: '/newsevent/' },
      { label: t('Menu.MENU_PROMOTION'), href: '/promotion/' },
      { label: t('Menu.MENU_MEMBERSHIP'), href: '/perfectgift/membership/' },
      { label: t('Menu.MENU_CONTACT_US'), href: '/contact/' },
      { label: t('Menu.MENU_REDEEMGIFT'), href: '/onlinegift/redeem/' }
    ],
    []
  )

  const accordionItems = useMemo(
    () => [
      { title: t('Menu.MENU_GIFT'), lists: gifts },
      { title: t('Menu.MENU_LOCATION'), lists: destinations },
      { title: t('Menu.MENU_SPA_PACKAGES'), lists: packages },
      { title: t('Menu.MASSAGE_MENU'), lists: massages },
      { title: t('Menu.MENU_SPA_TREATMENT'), lists: treatments },
      { title: t('Menu.MENU_ABOUT_US'), lists: abouts }
    ],
    []
  )
  console.log(0, accordionItems)

  // __RENDER
  return (
    <ModalContent className='bg-theme-light'>
      {(onClose) => (
        <>
          <ModalHeader className='grid grid-cols-3 items-center justify-between'>
            <div className='flex justify-start gap-1'>
              <Button
                className='bg-transparent'
                radius='sm'
                size='md'
                isIconOnly
                onPress={onClose}
                aria-label='Close hamberger menu'>
                <XIcon className='size-7 text-theme-orange' strokeWidth={2} />
              </Button>
            </div>

            <div className='flex justify-center'>
              <Image
                className='size-12 object-contain object-center'
                src='/static/images/logo-sm.png'
                width={48}
                height={48}
                quality={100}
                priority
                alt='Oasis Spa Origin logo'
              />
            </div>

            <div className='flex justify-end gap-1'></div>
          </ModalHeader>

          <ModalBody className='gap-4 p-4'>
            <div className='grid grid-cols-3 gap-2'>
              {items.map((record, index) => (
                <Button
                  key={index}
                  as={Link}
                  href={record.href}
                  className='rounded'
                  color='primary'
                  size='sm'
                  onPress={onClose}
                  aria-label={record.label}>
                  <p
                    className='text-xs font-normal uppercase tracking-wider'
                    dangerouslySetInnerHTML={{ __html: record.label }}
                  />
                </Button>
              ))}
            </div>

            <Divider className='bg-theme-100' />

            <Accordion
              className='px-0'
              defaultExpandedKeys={['accordion-1']}
              itemClasses={{
                title: 'text-sm font-bold uppercase text-theme',
                trigger: 'py-2',
                indicator: 'text-base'
              }}
              dividerProps={{
                className: 'my-2 bg-theme-100'
              }}>
              {accordionItems.map((record, index) => (
                <AccordionItem
                  key={`accordion-${index}`}
                  title={record.title}
                  aria-label={`Accordion ${index}`}
                  startContent={<div className='size-3 rounded-full bg-theme-200' />}>
                  <div className='grid gap-1'>
                    {record.lists.map((li) => (
                      <Button
                        key={li.label}
                        as={Link}
                        href={li.href + '/'}
                        color='primary'
                        variant='flat'
                        size='sm'
                        radius='none'
                        onPress={onClose}
                        aria-label={t(li.label)}>
                        <p
                          className='text-xs font-medium uppercase tracking-wider text-theme'
                          dangerouslySetInnerHTML={{ __html: t(li.label) }}
                        />
                      </Button>
                    ))}
                  </div>
                </AccordionItem>
              ))}
            </Accordion>
          </ModalBody>

          <ModalFooter className='justify-start p-4'>
            <p className='copyright select-none text-xe text-zinc-400'>
              &copy;2017-2025, THE OASIS SPA (THAILAND) All Rights Reserved.
              <br /> Web Hosting and Web Design by TAP Technology.
            </p>
          </ModalFooter>
        </>
      )}
    </ModalContent>
  )
}
