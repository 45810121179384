'use client'

import { Link } from '@nextui-org/react'
import Image from 'next/image'

export function BrandComponent() {
  // __STATE's
  const state = [
    { src: '/static/images/brands/kin.webp', alt: 'Kinnatural', url: 'https://www.kinnatural.com' },
    {
      src: '/static/images/brands/tos.webp',
      alt: 'Thai Oasis School',
      url: 'https://www.oasisschool.biz'
    },
    {
      src: '/static/images/brands/tap10.webp',
      alt: 'TAP Technology',
      url: 'https://www.tap10.com'
    },
    {
      src: '/static/images/brands/cafe.webp',
      alt: 'Cafe de Oasis',
      url: 'https://www.facebook.com/CafeDeOasisCM'
    }
  ]

  // __RENDER
  return (
    <div className='flex flex-row justify-center gap-4'>
      {state.map((record) => (
        <Link className='' href={record.url} isExternal key={record.alt} aria-label={record.alt}>
          <Image
            className='h-16 w-[100px] rounded-xl bg-white object-contain object-center p-2'
            src={record.src}
            width={100}
            height={64}
            quality={80}
            loading='lazy'
            alt={record.alt}
          />
        </Link>
      ))}
    </div>
  )
}
