'use client'

import { useLocale, useTranslations } from 'next-intl'
import { type ReactNode } from 'react'

import { Link, usePathname } from '@/libs/intl'
import { cls } from '@/libs/utils'

type Props = {
  label: string
  href: string
  className?: string
  children?: ReactNode
  isExternal?: boolean
}

export function NavLink({ label, href, isExternal = true, ...rest }: Props) {
  // __STATE's
  const locale = useLocale()
  const pathname = usePathname()
  const t = useTranslations()

  const className = cls('nav-link', { active: pathname === href }, rest?.className)

  const link = isExternal ? (
    <a
      className={className}
      href={`/${locale}${href}/`}
      aria-label={label}
      dangerouslySetInnerHTML={{ __html: t(label) }}
    />
  ) : (
    <Link
      className={className}
      href={href}
      aria-label={label}
      dangerouslySetInnerHTML={{ __html: t(label) }}
    />
  )

  // __RENDER
  if (rest?.children) {
    return (
      <div className='dropdown-wrapper relative'>
        {link}
        {rest.children}
      </div>
    )
  } else {
    return link
  }
}
