'use client'

import { Card } from '@nextui-org/card'
import { SquareArrowOutUpRightIcon } from 'lucide-react'
import { useLocale, useTranslations } from 'next-intl'

import { useCommonStore } from '@/libs/stores/common.store'
import { cls } from '@/libs/utils'

import { NavLink } from './link'

type Props = { items: IMenu[] }

export function DropdownComponent({ items }: Props) {
  // __RENDER
  return (
    <div className='dropdown-context'>
      <Card className='dropdown-inner' radius='none' shadow='lg' isBlurred>
        {items.map((record) => (
          <NavLink
            className='dropdown-link'
            href={record.href}
            label={record.label}
            key={record.label}
          />
        ))}
      </Card>
    </div>
  )
}

export function DestinationDropdownComponent() {
  // __STATE's
  const state = useCommonStore((state) => state.destinations)
  const locale = useLocale()
  const t = useTranslations()

  // __RENDER
  return (
    <div className='dropdown-context'>
      <Card
        className='dropdown-inner max-h-[640px] overflow-y-auto'
        radius='none'
        shadow='lg'
        isBlurred>
        <div className='ui--navbar-destination grid gap-2'>
          {state.map((record) => (
            <div className='relative min-w-60 rounded p-2 hover:bg-theme-200/20' key={record.label}>
              <div className='flex flex-row items-center justify-between gap-4 border-b border-b-theme-200/25 pb-1'>
                <a
                  className='select-none text-base font-bold uppercase text-theme'
                  href={`/${locale}${record.href}/`}
                  aria-label={record.label}>
                  {t(record.label)}
                </a>

                <a
                  className='btn size-8'
                  href={`/${locale}${record.href}/`}
                  aria-label={record.label}>
                  <SquareArrowOutUpRightIcon className='size-4' />
                </a>
              </div>

              <div
                className={cls('flex flex-col gap-2 pt-2', {
                  hidden: !record?.children?.length
                })}>
                {record.children?.map((child, index) => (
                  <a className='group text-zinc-900' href={child.href} key={index}>
                    <h4 className='line-clamp-1 text-sm font-bold leading-6 group-hover:underline'>
                      {child.label}
                    </h4>
                    <p className='line-clamp-2 text-xe text-zinc-600'>{child.description}</p>
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Card>
    </div>
  )
}
