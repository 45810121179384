'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Input } from '@nextui-org/react'
import { SendIcon } from 'lucide-react'
import { useTranslations } from 'next-intl'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { env } from '@/constants/configs'
import { useDialog } from '@/hooks'
import { createHeaders } from '@/services/helpers'

const formSchema = z.object({
  email: z.string().email(),
  csrf: z.string().optional()
})

type Form = z.infer<typeof formSchema>

export function SubscribeComponent() {
  // __STATE's
  const form = useForm<Form>({ resolver: zodResolver(formSchema) })
  const t = useTranslations()

  const dialog = useDialog()

  // __FUNCTION's
  const onSubmit = useCallback(
    async (formData: Form) => {
      const f = new FormData()
      f.append('email', formData.email)
      const headers = createHeaders()
      const response = await fetch(`${env.API_GATEWAY}/api/email/subscribe/`, {
        method: 'post',
        headers,
        body: f
      })
      const data = await response.json()

      if (data) {
        dialog.alert(data.message, { title: data.status })
        form.reset()
      }
    },
    [form]
  )

  // __RENDER
  return (
    <div className=''>
      <h4 className='mb-4 text-sm font-bold uppercase leading-6'>{t('Menu.MENU_TOUCH')}</h4>

      <form className='flex flex-row gap-1' onSubmit={form.handleSubmit(onSubmit)}>
        <Input
          classNames={{
            label: 'capitalize text-sm opacity-75',
            input: 'bg-transparent',
            innerWrapper: 'bg-transparent',
            inputWrapper: 'shadow-none sm:w-60 !bg-theme-200/20',
            errorMessage: 'max-w-60'
          }}
          key='.field-subscrib'
          type='email'
          label={t('Menu.PLACEHOLDER_EMAIL')}
          labelPlacement='inside'
          color='primary'
          variant='flat'
          size='sm'
          radius='lg'
          {...form.register('email', { required: true })}
        />

        <Button
          type='submit'
          color='secondary'
          size='lg'
          radius='lg'
          isIconOnly
          aria-label='Button submit subscrib'>
          <SendIcon className='size-5' />
        </Button>
      </form>
    </div>
  )
}
