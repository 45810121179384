import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\xampp\\htdocs\\oasis_website\\node_modules\\next-intl\\dist\\esm\\navigation\\shared\\BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\xampp\\htdocs\\oasis_website\\node_modules\\next-intl\\dist\\esm\\navigation\\shared\\LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "D:\\xampp\\htdocs\\oasis_website\\node_modules\\next\\dist\\client\\image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["BrandComponent"] */ "D:\\xampp\\htdocs\\oasis_website\\src\\components\\footer\\brands.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FollowComponent"] */ "D:\\xampp\\htdocs\\oasis_website\\src\\components\\footer\\follow.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LinkComponent"] */ "D:\\xampp\\htdocs\\oasis_website\\src\\components\\footer\\links.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SubscribeComponent"] */ "D:\\xampp\\htdocs\\oasis_website\\src\\components\\footer\\subscribe.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavbarComponent"] */ "D:\\xampp\\htdocs\\oasis_website\\src\\components\\navbar\\index.tsx");
