'use client'

import { useTranslations } from 'next-intl'

import { abouts, destinations, massages, packages, treatments } from '@/constants/menu'
import { Link } from '@/libs/intl'

export function LinkComponent() {
  // __STATE's
  const t = useTranslations()

  // __RENDER
  return (
    <div className='as-links flex flex-wrap justify-between'>
      <div className=''>
        <h4 className='mb-4 text-sm font-bold uppercase leading-6'>{t('Menu.MENU_LOCATION')}</h4>
        <LinkGroupComponent lists={destinations} key='MENU_LOCATION' />
      </div>

      <div className=''>
        <h4 className='mb-4 text-sm font-bold uppercase leading-6'>
          {t('Menu.MENU_SPA_PACKAGES')}
        </h4>
        <LinkGroupComponent lists={packages} key='MENU_SPA_PACKAGES' />
      </div>

      <div className=''>
        <h4 className='mb-4 text-sm font-bold uppercase leading-6'>{t('Menu.MASSAGE_MENU')}</h4>
        <LinkGroupComponent lists={massages} key='MASSAGE_MENU' />
      </div>

      <div className=''>
        <h4 className='mb-4 text-sm font-bold uppercase leading-6'>
          {t('Menu.MENU_SPA_TREATMENT')}
        </h4>
        <LinkGroupComponent lists={treatments} key='MENU_SPA_TREATMENT' />
      </div>

      <div className=''>
        <h4 className='mb-4 text-sm font-bold uppercase leading-6'>{t('Menu.MENU_ABOUT_US')}</h4>
        <LinkGroupComponent lists={abouts} key='MENU_ABOUT_US' />
      </div>
    </div>
  )
}

export function LinkGroupComponent({ lists }: { lists: IMenu[] }) {
  // __STATE's
  const t = useTranslations()

  // __RENDER
  return (
    <>
      {lists.map((record) => (
        <Link
          className='link'
          href={record.href}
          key={record.label}
          dangerouslySetInnerHTML={{
            __html: t(record.label).toLowerCase()
          }}
        />
      ))}
    </>
  )
}
