'use client'

import Image from 'next/image'

import { gifts, massages, packages, treatments } from '@/constants/menu'

import { DestinationDropdownComponent, DropdownComponent } from './dropdown'
import { NavLink } from './link'
import { FnsComponent } from './md'
import { TranslateComponent } from './translate'

export function NavbarComponent() {
  // __RENDER
  return (
    <nav className='ui--navbar'>
      <div className='ui--navbar-container for-lg'>
        <div className='ui--navbar-grid'>
          <div className='columns flex justify-end'>
            <NavLink href='/' label='Menu.MENU_HOME' key='.home' isExternal={false} />

            <NavLink href='/destination' label='Menu.MENU_LOCATION' key='.destination'>
              <DestinationDropdownComponent />
            </NavLink>

            <NavLink href='/promotion' label='Menu.MENU_PROMOTION' key='.promotion' />

            <NavLink href='/package' label='Menu.MENU_SPA_PACKAGES' key='.package'>
              <DropdownComponent items={packages} />
            </NavLink>

            <NavLink href='/treatment' label='Menu.MENU_SPA_TREATMENT' key='.treatment'>
              <DropdownComponent items={treatments} />
            </NavLink>
          </div>

          <div className='columns'>
            <Image
              className='mx-auto size-20 object-contain object-center brightness-0 invert-[1]'
              src='/static/images/logo-sm.png'
              width={100}
              height={100}
              quality={100}
              priority
              alt='Oasis Spa Origin white logo'
            />
          </div>

          <div className='columns flex justify-start'>
            <NavLink href='/massage' label='Menu.MASSAGE_MENU' key='.massage'>
              <DropdownComponent items={massages} />
            </NavLink>

            <NavLink href='/perfectgift/giftcertificate' label='Menu.MENU_GIFT' key='.gift'>
              <DropdownComponent items={gifts} />
            </NavLink>

            <NavLink
              href='/perfectgift/membership'
              label='Menu.MENU_MEMBERSHIP'
              key='.membership'
            />

            {/* <Button
              className='ml-2 self-center bg-transparent'
              radius='none'
              size='md'
              isIconOnly
              disableRipple
              aria-label='Menu shopping cart'>
              <ShoppingCart className='text-xl text-white/80' />
            </Button> */}

            <TranslateComponent />
          </div>
        </div>
      </div>

      <FnsComponent />
    </nav>
  )
}
